import React, { Component } from 'react'

class RedirectExternal extends Component {
  render() {
    const {
      pageContext: { dest },
    } = this.props

    const content = `0;URL='${dest}'`
    return <meta httpEquiv="refresh" content={content} />
  }

  componentDidMount() {
    const {
      pageContext: { dest },
    } = this.props

    if (window) {
      window.location = dest
    }
  }
}

export default RedirectExternal
